<template>
  <div>
    <select v-model="item.userClientStepBookingDuration" size="sm" class="form-control background-light-blue-inputs">
      <option
        :value="item.duration"
        v-for="item in duration"
      >
        {{ item.value }} {{ $t('general.actions.time.minutes') }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: this
    }
  },
  data() {
    return {
      duration: [
        { value: 5, duration: 300 },
        { value: 10, duration: 600 },
        { value: 15, duration: 900 },
        { value: 20, duration: 1200 },
        { value: 30, duration: 1800 },
        { value: 40, duration: 2400 },
        { value: 45, duration: 2700 },
        { value: 60, duration: 3600 },
        { value: 75, duration: 4500 },
        { value: 80, duration: 4800 },
        { value: 90, duration: 5400 },
        { value: 120, duration: 7200 },
        { value: 180, duration: 10800 },
        { value: 240, duration: 14400 }
      ],
    }
  },
  methods: {
    generateDuration() {
      const durations = [5, 10, 15, 20, 30, 40, 45, 60, 75, 80, 90, 120, 180, 240];
      return values;
    }
  }
}
</script>
<style scoped>

</style>
